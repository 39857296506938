<template>
  <div class="full_col">         
    <form accept-charset="UTF-8" v-on:submit="onSubmit">
      <div class="form-group">
        <div v-if="submiterror"><p class="alert alert-danger">{{ submiterror }}</p></div>
      </div>
      
      <div><p class="formerror"><b>{{ $t('Please fill in all fields marked with red') }}</b></p></div>
      <div class="form-group" v-if="!rental">
        <label>{{ $t('Rentals') }}<span class="required_item">*</span></label>
        <select class="form-control"
                name="rental"
                v-model="rental">
          <option v-for="(property) in properties"
                  :key="property"
                  :value="property.bolig_blok[0].title + ' ' + property.title + ' ( ' + property.bolignr + ' )'">{{ property.bolig_blok[0].title }} {{ property.title }} ( {{ property.bolignr }} )</option>
        </select>
      </div>
      <div class="form-group" v-if="rental">
        <label>{{ $t('Rentals') }}<span class="required_item">*</span></label>
        <input type="text" class="form-control" name="rental" v-model="rental" readonly>
      </div>
      <div class="form-group">
        <label>{{ $t('Companyname') }}</label>
        <input type="text" class="form-control" name="companyname" v-model="companyname">
      </div>
      <div class="form-group">
        <Label>{{ $t('Contactperson') }}</label>
        <input type="text" class="form-control" name="contactperson" v-model="contactperson">
      </div>
      <div class="form-group">
        <label>{{ $t('Email') }}</label>
        <input type="email" class="form-control" name="email" v-model="email">
      </div>
      <div class="form-group">
        <label>{{ $t('Contact phone') }}</label>
        <input type="text" class="form-control" name="cellphone" v-model="cellphone">
      </div>
      <div class="form-group">
        <label>{{ $t('Message') }}</label>
        <textarea rows="10" class="form-control" name="message" v-model="message"></textarea>
      </div>
      <div>
        <button name="submit" type="submit" value="Submit" class="button button-border button-rounded button-large">{{ $t('Yes - Please add me to the list') }}</button>
        <p><br>
          <b>{{$t('Renmark') }}</b><br>
          <i>{{ $t('We save your application for private tenancies for 3 months from the date of receipt. If you do not receive a response within 3 months, you must reapply to be on the waiting list.') }}</i>
        </p>
      </div>
      <div class="clear"></div>
      <input type="hidden" name="g-recaptcha-response" v-model="googleToken" />
      <GoogleCaptcha sitekey="6LdJ8LMqAAAAAEWyXFnNKswkINHnrhrfcJKwFTQe" action="submit" v-on:google_captcha_token="updateToken" ref="captcha"></GoogleCaptcha>
    </form>
    <div class="vld-parent">
      <Loading :active.sync="isLoading"
               :can-cancel="true"
               :is-full-page="fullPage"
               :color="loadingColor"
               ></Loading>
    </div>

  </div>
</template>

<script>
  import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'
  import { backendUrl } from '../generalSettings.js'
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'

  import GoogleCaptcha from 'vue2-recaptchav3';
  
  export default {
  name: 'StandbylistForm',
  props: ['rental', 'rentalTitle'],
  components: {
      Loading,
      GoogleCaptcha: GoogleCaptcha
  },
  data () {
  return {
      isLoading:false,
      fullPage: true,
      loader: 'dots',
      loadingColor: '#f89520',
      loadingOpacity: 0.5,
      errormessage: 'Feltet skal udfyldes',
      submiterror: '',
      errors: [],
      formSettings: [],
      message: '',
      companyname: '',
      contactperson: '',
      email: '',
      cellphone: '',
      properties: [],
      googleToken: null,
      }
  },
  created() {
      this.getJson()
      if(!this.rental) {
          this.fetchProperties()
      }
  },
  methods: {
     updateToken(payload){
         this.googleToken = payload.response_token;
     },
     fetchProperties ($building) {
         this.$apollo.query({
             query: GET_PROPERTY_QUERY,
                 variables: {
                     building: $building,
                     siteId: this.$i18n.siteId
                 }
             }).then(function (response) {
                 if(response.data.property.length > 0) {
                     this.properties = response.data.property
                 }
             }.bind(this))
     },
     getErrors: function (property) {
          const errors = [];
          if (!this.errors.length) {
              return errors;
           }
           for (let i = 0; i < this.errors.length; i++) {
              if (this.errors[i].key === property) {
                  errors.push(this.errors[i].message);
              }
           }
           return errors;
      },
      getJson: function() {
           var requestUrl = backendUrl + '/standbylistda.html'
           if(this.$i18n.siteId == 1) {
               requestUrl = backendUrl + '/standbylistda.html'
           } else if(this.$i18n.siteId == 4) {
               requestUrl = backendUrl + '/standbylisten.html'
           } else if(this.$i18n.siteId == 3) {
               requestUrl = backendUrl + '/standbylistde.html'
           }                            
           fetch(requestUrl)
               .then(r => r.json())
               .then(json => { this.formSettings = json; })
      },

      validate: function (property) {
        if (!property) {
          this.errors = [];
        } else {
          this.errors = this.errors.filter((item) => item.key !== property);
        }
        if ((!property || property === 'companyname') && !this.companyname) {
          this.errors.push({ key: 'companyname', message: this.errormessage });
        }
        if ((!property || property === 'contactperson') && !this.contactperson) {
          this.errors.push({ key: 'contactperson', message: this.contactperson });
        }
        if ((!property || property === 'email') && !this.email) {
          this.errors.push({ key: 'email', message: this.errormessage });
        }
        if ((!property || property === 'cellphone') && !this.cellphone) {
          this.errors.push({ key: 'cellphone', message: this.errormessage });
        }
      },
      onSubmit: function (evt) {
        this.validate();
        if (this.errors.length) {
         evt.stopPropagation();
         evt.preventDefault();
          return false;
        }

        this.isLoading = true;
        const form = evt.target;
        const data = new FormData(form);
        const request = new XMLHttpRequest();

        // Add action which handles freeform validation
        data.append('action', 'freeform/submit');
        data.append('freeform-action', 'submit');

        this.$refs.captcha.generateManualToken().then((token) => {
            this.googleToken = token;
        });
    
        // Add the form's hash, so that it gets recognized by the form service
        data.append('formHash', this.formSettings.hash);
        data.append(this.formSettings.csrf.name, this.formSettings.csrf.value);
        data.append('freeform_payload', this.formSettings.payload);
        
        // Create a request
        request.open('POST', backendUrl, true);
        request.setRequestHeader("Cache-Control", "no-cache");

        // Add the AJAX headers specific to Craft CMS
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

        // Attach the response handler callback
        request.onload = () => {

        this.isLoading = false

        if (request.status === 200) {
            const response = JSON.parse(request.response);
            const { success, finished, errors, formErrors } = response
            if (success && finished) {
              // Reset the form so that the user may enter fresh information
              form.reset();
              this.submiterror = ''
            this.$router.push(response.returnUrl)
            } else if (errors || formErrors) {
                this.submiterror = this.$i18n.t('An error occurred. Please check that everything is filled out correctly and try again')
            }
          }
        };

        // Send the request
        request.send(data);

        evt.stopPropagation();
        evt.preventDefault();
        return false;
      },

  }
}
</script>

<style>
</style>
